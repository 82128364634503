import React, { FC, useEffect, useState } from "react";
import Heading from "../../../../../shared/components/Heading";
import UICarousel from "../../../../../shared/components/UICarousel";
import ViewAll from "../../../../../assets/icon/viewall.svg";
import "./chapter.scss";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { CHAPTER } from "../../../../../routes/routeConstants/appRoutes";
import { ContentService } from "../../../../../services/ContentService/content.service";
import { ChapterContent } from "../../../../../models/Chapters/chapter.model";
import AuthContainer from "../../../../../store/container/AuthContainer";
import VideoCard from "../../../../../shared/components/VideoCard";
import { Spin } from "antd";

interface ChapterProps {
  chapterNo: number;
  title: string;
  id?: number;
  subjectName: string;
  courseId: number;
  disabled?: boolean;
  onSubscription?: () => void;
}

interface RouteParams {
  subjectId: string;
}

const Chapter: FC<ChapterProps> = ({
  id,
  title,
  chapterNo,
  courseId,
  subjectName,
  disabled = false,
  onSubscription,
}) => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<any>([]);

  const handleViewAll = () => {
    const URL = generatePath(CHAPTER, {
      ...params,
      id,
      chapterId: id,
    });
    history.push({
      pathname: URL,
      state: { id, subjectName: subjectName, chapterName: title },
    });
  };

  useEffect(() => {
    ContentService.getChapterContents(
      {},
      {
        courseId: courseId,
        chapterId: id,
      },
      (contents: ChapterContent[]) => {
        setVideos(
          contents?.map((content, index) => {
            return (
              <VideoCard
                disabled={disabled}
                onSubscription={undefined}
                key={content?.id + "_" + index}
                id={content?.id}
                color="#FD6C00"
                subject={subjectName}
                thumbnail={content?.contentLibrary?.thumbnailUrl}
                content={content.title}
                description={content.description}
                contentLibrary={content.contentLibrary}
                url={content?.contentLibrary?.attachmentUrl}
                contentType={content?.contentLibrary?.contentType}
                chapterId={id}
                chapterName={title}
                subjectId={parseInt(params.subjectId) || undefined}
              />
            );
          })
        );
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <div>
      <div className="chapter__container">
        <div className="title">
          <div>
            <h1>{chapterNo}</h1>
            <Heading>{title}</Heading>
          </div>
          <div>
            <p onClick={disabled ? onSubscription : handleViewAll}>View all</p>
            <img src={ViewAll} alt="" />
          </div>
        </div>
        {isLoading ? (
          <div className="loading__container">
            <Spin size="large" tip="Loading..." />
          </div>
        ) : (
          <div className="chapter-content">
            <UICarousel data={videos} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthContainer(Chapter);
